import {get,post} from "../http/index";

//获取招聘信息集合
export const recruitInfoQueryList = () => get('/recruitInfo/queryList');

//删除
export const recruitInfoDelete = (params) => get('/recruitInfo/delete/' + params);

//详情
export const recruitInfoDetails = (params) => get('/recruitInfo/detail/' + params);

//保存
export const recruitInfoSave = (params) => post('/recruitInfo/save',params)

//修改保存
export const recruitInfoUpdate = (params) => post('/recruitInfo/update',params)

