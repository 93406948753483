<template>
    <div style="margin-bottom: -150px;">
      <div class="content-son">
        <el-select class="selection" @change="getDetails" v-model="value">
          <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
        <div class="content-font">
          <div class="content-font-top">
<!--            <span style="font-weight: bold; color: #000">岗位职责：</span>-->
<!--            <span>1、根据项目进度要求设计方案、绘制效果图；</span>-->
<!--            <span>2、执行、落实事前指导和设计校审中提出的要求，将设计成果整理完善；</span>-->
<!--            <span>3、协调项目、施工部门，实施相关设计工作；</span>-->
<!--            <span>4、保证设计工作准确、高效的推进；</span>-->
<!--            <span>5、把控方案深化、施工图等工作质量；</span>-->
<!--            <span>6、施工交底 ，协调施工中设计问题的处理。</span>-->
            <div v-html="dataObj.jobRequirements"></div>
          </div>
          <div class="content-font-bottom">
<!--            <span style="font-weight: bold;  color: #000">任职要求：</span>-->
<!--            <span>1、美术/设计专业本科以上学历；</span>-->
<!--            <span>2、熟练掌握Indesign、AI、Photoshop、Illustrator、coreldRAW图形等相关设计软件、办公软件；</span>-->
<!--            <span>3、对创意充满热情，拥有独特的视觉表现力，善于独立思考与创新，做事认真，讲求细节，乐于沟通交流；</span>-->
<!--            <span>4、3年以上平面设计师/资深平面设计师/设计主管等岗位从业经验；</span>-->
<!--            <span> 5、有深厚的美学功底，较强的审美和创意能力，会手绘等技能优先考虑。；</span>-->
            <div v-html="dataObj.jobResponsibilities"></div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { recruitInfoQueryList, recruitInfoDetails } from '../../api/joinAdmin'
export default {
  name: "recruitWiseMen",
  data(){
    return{
      options: [],
      value:null,
      dataObj:{}
    }
  },
  mounted() {
    this.getList();
  },
  created() {

  },
  methods:{
    async getList() {
      let defId;
      await recruitInfoQueryList().then(res => {
        this.options = res
        for (let i = 0; i < this.options.length; i++) {
          defId = this.options[0].id
          this.value = this.options[0].name
        }
      })
      console.log('defId',defId)
      await recruitInfoDetails(defId).then(res => {
        this.dataObj = res
      })
    },
    getDetails(id){
      recruitInfoDetails(id).then(res => {
        this.dataObj = res
      })
    }
  },
}
</script>

<style scoped lang="less">
.content-son{
  width: 979px;
  margin-left: 15px;
  margin-bottom: 200px;
  line-height: 33px;
}
  .content-font{
    span{
      display: block;
      color: rgb(153,153,153);
      line-height: 34px;
    }
    .content-font-bottom{
      margin: 30px 0;
    }
  }
  .selection{

  }
  /deep/.el-select{
    margin: 24px 0;
  }
  /deep/.el-input__inner{
    -webkit-appearance: none;
    background-color: #FFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #000 !important;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    outline: 0;
    font-size: 19px;
    padding: 0 15px;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    width: 100%;
    font-weight: bold;
  }
/deep/.el-input__inner {
  &::placeholder {
    color: #000;
    font-size: 19px;
  }
}
</style>
